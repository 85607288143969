<template>
  <Loader v-if="this.$store.getters.GET_LOADER_VISIBLE" />
  <div class="row justify-content-center">

    <div class="col-xl-10 col-lg-12 col-md-9">

      <div class="card o-hidden border-0 shadow-lg my-5">
        <div class="card-body p-0">
          <!-- Nested Row within Card Body -->
          <div class="row">
            <div class="col-lg-6 d-none d-lg-block bg-password-image"></div>
            <div class="col-lg-6">
              <div class="p-5">
                <div class="text-center">
                  <h1 class="h4 text-gray-900 mb-2">Забыли свой пароль?</h1>
                  <p class="mb-4">
                    Мы понимаем это, всякое случается.
                    Просто введите свой адрес электронной почты ниже, и мы вышлем вам ссылку для сброса пароля!
                  </p>
                </div>
                <form class="user">
                  <div class="form-group">
                    <input type="email" class="form-control form-control-user"
                           id="exampleInputEmail" aria-describedby="emailHelp"
                           placeholder="Введите Email адресс...">
                  </div>
                  <a href="login.html" class="btn btn-primary btn-user btn-block">
                    Сбросить пароль
                  </a>
                </form>
                <hr>
                <div class="text-center">
                  <router-link class="small" to="create">Создать аккаунт!</router-link>
                </div>
                <div class="text-center">
                  <router-link class="small" to="login">Вспомнили пароль? Авторизуйтесь!</router-link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

    </div>

  </div>
</template>
<script>
import Loader from "../../components/static/Loader";
export default {
  name: "ForgotPage",
  components: {Loader}
}
</script>
<style scoped></style>
